import React from "react"
import { connect } from "react-redux"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { sendLeadForm } from "./../../actions/mailing"
import { setAlert } from "./../../actions/alert"
import { SWITCH_PROMO } from "./../../actions/actionTypes"
import PropTypes from "prop-types"
import { nl2br } from "../../utils/functions"
import { navigate } from "gatsby"


function Danke({
    translations,
    lang,
}) {
    const closeContactForm = () => {
        //removePromo();
        navigate(lang === "de" ? "/" : "/en/")
    }

    return (
        <Layout>
            <SEO title={translations.contact_us_title} description={translations.contact_us_meta_description} />
            <div className="subpage lead thank-you-page">
                <div className="lightholder"></div>
                <section>
                    <button
                        className="contact-closer"
                        onClick={closeContactForm}
                        title={translations.contact_closer_title}
                    >
                        <span></span>
                        <span></span>
                    </button>
                    <div className="flex column center">

                        {/* <Image img={fiboLogo} img2={fiboLarge} alt="FIBO" className="fibo-logo" styles="fibo-img" /> */}
                        <p className="halyard thanx">{nl2br(translations.thanx)}!</p>
                        <p className="halyard thanx-message centered">{nl2br(translations.thanx_message)}!</p>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

Danke.propTypes = {
    sendLeadForm: PropTypes.func.isRequired,
    setAlert: PropTypes.func,
}

const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    serverCall: state.preferences.serverCall,
})

const mapDispatchToProps = dispatch => ({
    removePromo: () => {
        dispatch({ type: SWITCH_PROMO, payload: false })
    },
    sendLeadForm: formData => {
        dispatch(sendLeadForm(formData))
    },
    setAlert: (text, type) => {
        dispatch(setAlert(text, type))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Danke)
