import React from 'react'
import { connect } from 'react-redux'
import Danke from '../../contact-us/danke'

function DankeEn({ translations, lang }) {
    return (
        <Danke translations={translations} lang={lang} />
    )
}


const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    serverCall: state.preferences.serverCall
})

export default connect(mapStateToProps)(DankeEn)